<div *ngIf="hero">
  <h2>{{ hero.name | uppercase }} Details</h2>

  <div>
    <span>id: </span> {{ hero.id }}
  </div>

  <div>
    <label>name:
      <input [(ngModel)]="hero.name" placeholder="name" />
    </label>
  </div>
</div>

<button (click)="goBack()">Go Back</button>
<button (click)="save()">Save</button>