<h3>Top Heroes</h3>

<div class="grid grid-pad">
  <a
    *ngFor="let hero of heroes"
    routerLink="/detail/{{ hero.id }}"
    class="col-1-4">
    <div class="module hero">
      <h4>{{ hero.name }}</h4>
    </div>
  </a>
</div>

<app-hero-search></app-hero-search>