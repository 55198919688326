<h2>My Heroes</h2>

<ul class="heroes">
  <li *ngFor="let hero of heroes">
    <a routerLink="/detail/{{hero.id}}">
      <span class="badge">{{ hero.id }}</span> {{ hero.name }}
    </a>
    <button class="delete" title="Delete Hero" (click)="delete(hero)">x</button>
  </li>
</ul>

<div>
  <label>Hero Name
    <input #heroName />
  </label>

  <button (click)="add(heroName.value); heroName.value = ''">Add</button>
</div>